
import { defineComponent, PropType } from 'vue';
import {
  DmTile,
  DmLoadingBlend
} from '@dobrymechanik/vue-ui';
import { StatsChartPoint } from '@/api/models';
import VueApexCharts from 'vue3-apexcharts';
import { ApexOptions } from 'apexcharts';

export default defineComponent({
  name: 'StatsChart',
  components: {
    DmTile,
    DmLoadingBlend,
    VueApexCharts
  },
  props: {
    data: {
      type: Array as PropType<StatsChartPoint[]>,
      required: true
    },
    loading: {
      type: Boolean as PropType<boolean>,
      required: true
    }
  },
  computed: {
    chartOptions (): ApexOptions {
      return {
        chart: {
          id: 'garagestatistics',
          toolbar: {
            show: false
          }
        },
        colors: ['#172897', '#E8C34D'],
        xaxis: {
          categories: this.data.map(p => p.label)
        },
        tooltip: {
        }
      };
    },
    series (): any[] {
      return [
        {
          name: this.$t('statistics.chart.analyzedPeriod'),
          data: this.data.map(p => p.primary)
        },
        {
          name: this.$t('statistics.chart.comparativePeriod'),
          data: this.data.map(p => p.compared)
        }
      ];
    }
  }
});
