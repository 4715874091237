import { toDisplayString as _toDisplayString, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, resolveComponent as _resolveComponent, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DmLoadingBlend = _resolveComponent("DmLoadingBlend")
  const _component_AppEventModal = _resolveComponent("AppEventModal")
  const _component_DmTile = _resolveComponent("DmTile")

  return (_openBlock(), _createBlock(_component_DmTile, {
    color: "white",
    rounded: "",
    bordered: "",
    class: _ctx.$bem({})
  }, {
    default: _withCtx(() => [
      _createVNode("h3", {
        class: _ctx.$bem({ e: 'title' })
      }, _toDisplayString(_ctx.$t('statistics.topRepairs.title')), 3),
      _createVNode("ul", {
        class: _ctx.$bem({ e: 'repairs' })
      }, [
        (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.items, (repair) => {
          return (_openBlock(), _createBlock("li", {
            key: repair.id,
            class: [_ctx.$bem({ e: 'repair' }), "is-hoverable"],
            onClick: ($event: any) => (_ctx.showEvent(repair.id))
          }, [
            _createVNode("div", {
              class: _ctx.$bem({ e: 'repair-car' })
            }, _toDisplayString(repair.brand) + " " + _toDisplayString(repair.model), 3),
            _createVNode("div", {
              class: _ctx.$bem({ e: 'repair-details' })
            }, [
              _createVNode("span", null, _toDisplayString(repair.licensePlateNumber), 1),
              _createVNode("span", null, _toDisplayString(_ctx.getReadablePrice(repair.price)), 1)
            ], 2)
          ], 10, ["onClick"]))
        }), 128))
      ], 2),
      _createVNode(_component_DmLoadingBlend, { loading: _ctx.loading }, null, 8, ["loading"]),
      _createVNode(_component_AppEventModal, {
        id: _ctx.eventId,
        modelValue: _ctx.isEventModalActive,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.isEventModalActive = $event)),
        onClose: _ctx.closeEvent
      }, null, 8, ["id", "modelValue", "onClose"])
    ]),
    _: 1
  }, 8, ["class"]))
}