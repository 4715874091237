import { toDisplayString as _toDisplayString, createVNode as _createVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, withCtx as _withCtx } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DmSpinner = _resolveComponent("DmSpinner")
  const _component_DmIcon = _resolveComponent("DmIcon")
  const _component_DmTile = _resolveComponent("DmTile")

  return (_openBlock(), _createBlock(_component_DmTile, {
    color: "white",
    rounded: "",
    bordered: "",
    class: _ctx.$bem({}),
    "data-test": "stats-boxes-item"
  }, {
    default: _withCtx(() => [
      _createVNode("div", {
        class: _ctx.$bem({e: 'title'})
      }, _toDisplayString(_ctx.title), 3),
      _createVNode("div", {
        class: _ctx.$bem({e: 'value-wrapper'})
      }, [
        _createVNode("div", {
          class: _ctx.$bem({e: 'value'})
        }, [
          (_ctx.loading)
            ? (_openBlock(), _createBlock(_component_DmSpinner, {
                key: 0,
                size: "1x"
              }))
            : _renderSlot(_ctx.$slots, "value", { key: 1 })
        ], 2),
        (_ctx.icon)
          ? (_openBlock(), _createBlock("div", {
              key: 0,
              class: _ctx.$bem({e: 'icon'})
            }, [
              _createVNode(_component_DmIcon, {
                name: _ctx.icon,
                type: "fa",
                prefix: "fa-"
              }, null, 8, ["name"])
            ], 2))
          : _createCommentVNode("", true)
      ], 2),
      _createVNode("div", null, [
        (_ctx.loading)
          ? (_openBlock(), _createBlock("span", _hoisted_1, "   "))
          : _renderSlot(_ctx.$slots, "description", { key: 1 })
      ])
    ]),
    _: 3
  }, 8, ["class"]))
}