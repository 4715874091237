
import { defineComponent, PropType } from 'vue';
import {
  DmTile,
  DmLoadingBlend
} from '@dobrymechanik/vue-ui';
import { AppEventModal } from '@/components';

export default defineComponent({
  name: 'StatsTopRepairs',
  components: {
    DmTile,
    DmLoadingBlend,
    AppEventModal
  },
  props: {
    items: {
      type: Array as PropType<any[]>,
      required: true
    },
    loading: {
      type: Boolean as PropType<boolean>,
      required: true
    }
  },
  data () {
    return {
      isEventModalActive: false as boolean,
      eventId: '' as string
    };
  },
  methods: {
    getReadablePrice (price: number | null | undefined): string {
      return price ? ((price / 100).toFixed(2)).split('.').join(',') : '';
    },
    showEvent (id: string): void {
      this.eventId = id;
      this.isEventModalActive = true;
    },
    closeEvent (): void {
      this.isEventModalActive = false;
    }
  }
});
