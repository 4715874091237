
import { defineComponent, PropType } from 'vue';
import { StatsBox } from '@/api/models';
import StatsBoxesItem from './Item';
import {
  DmSimpleGrid,
  DmSimpleGridItem
} from '@dobrymechanik/vue-ui';

export default defineComponent({
  name: 'StatsBoxes',
  components: {
    DmSimpleGrid,
    DmSimpleGridItem,
    StatsBoxesItem
  },
  props: {
    repairsCount: {
      type: Object as PropType<StatsBox>,
      required: true
    },
    repairsTotalValue: {
      type: Object as PropType<StatsBox>,
      required: true
    },
    repairsAverageValue: {
      type: Object as PropType<StatsBox>,
      required: true
    },
    cancellationsCount: {
      type: Object as PropType<StatsBox>,
      required: true
    },
    clientsCount: {
      type: Object as PropType<StatsBox>,
      required: true
    },
    newClientsCount: {
      type: Object as PropType<StatsBox>,
      required: true
    },
    returningClientsCount: {
      type: Object as PropType<StatsBox>,
      required: true
    },
    comparativePeriod: {
      type: String as PropType<string | null>,
      default: null
    },
    loading: {
      type: Boolean as PropType<boolean>,
      required: true
    }
  },
  methods: {
    getPercentage (value: number): string {
      if (value === Infinity || isNaN(value)) {
        return '-';
      }
      return `${value > 0 ? '+' : ''}${Math.round(value * 100)}%`;
    },
    getDiff (value: number): string {
      return `${value > 0 ? '+' : ''}${value}`;
    },
    comparisonClass (firstValue: number, secondValue: number): string {
      const diff = firstValue - secondValue;
      return diff > 0 ? 'has-color-success' : diff < 0 ? 'has-color-error' : '';
    }
  }
});
