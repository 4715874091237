import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_StatsControls = _resolveComponent("StatsControls")
  const _component_StatsTopRepairs = _resolveComponent("StatsTopRepairs")
  const _component_StatsRepairsSources = _resolveComponent("StatsRepairsSources")
  const _component_DmGridItem = _resolveComponent("DmGridItem")
  const _component_StatsChart = _resolveComponent("StatsChart")
  const _component_StatsBoxes = _resolveComponent("StatsBoxes")
  const _component_DmGrid = _resolveComponent("DmGrid")
  const _component_DmAsyncContent = _resolveComponent("DmAsyncContent")
  const _component_AppWrapper = _resolveComponent("AppWrapper")

  return (_openBlock(), _createBlock(_component_AppWrapper, {
    class: _ctx.$bem({}),
    "data-test": "stats-page"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_StatsControls, {
        "period-type": _ctx.periodType,
        "analyzed-period": _ctx.analyzedPeriod,
        "comparative-period": _ctx.comparativePeriod,
        periods: _ctx.periods,
        workspace: _ctx.workspace,
        workspaces: _ctx.workspaces,
        onSetPeriodType: _ctx.setPeriodType,
        onSetAnalyzedPeriod: _ctx.setAnalyzedPeriod,
        onSetComparativePeriod: _ctx.setComparativePeriod,
        onSetWorkspace: _ctx.setWorkspace
      }, null, 8, ["period-type", "analyzed-period", "comparative-period", "periods", "workspace", "workspaces", "onSetPeriodType", "onSetAnalyzedPeriod", "onSetComparativePeriod", "onSetWorkspace"]),
      _createVNode(_component_DmAsyncContent, {
        loading: false,
        error: _ctx.error,
        reloadable: "",
        onReload: _ctx.fetchStats
      }, {
        default: _withCtx(() => [
          _createVNode(_component_DmGrid, { "spacings-size": "md" }, {
            default: _withCtx(() => [
              _createVNode(_component_DmGridItem, {
                "cols-md": 4,
                "cols-lg": 3
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_StatsTopRepairs, {
                    items: _ctx.topRepairs,
                    loading: _ctx.loading
                  }, null, 8, ["items", "loading"]),
                  _createVNode(_component_StatsRepairsSources, {
                    items: _ctx.repairSources,
                    loading: _ctx.loading
                  }, null, 8, ["items", "loading"])
                ]),
                _: 1
              }),
              _createVNode(_component_DmGridItem, {
                "cols-md": 8,
                "cols-lg": 9
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_StatsChart, {
                    data: _ctx.chartData,
                    loading: _ctx.loading
                  }, null, 8, ["data", "loading"]),
                  _createVNode(_component_StatsBoxes, {
                    loading: _ctx.loading,
                    "repairs-count": _ctx.repairsCount,
                    "repairs-total-value": _ctx.repairsTotalValue,
                    "repairs-average-value": _ctx.repairsAverageValue,
                    "cancellations-count": _ctx.cancellationsCount,
                    "clients-count": _ctx.clientsCount,
                    "new-clients-count": _ctx.newClientsCount,
                    "returning-clients-count": _ctx.returningClientsCount,
                    "comparative-period": _ctx.comparativePeriod.text
                  }, null, 8, ["loading", "repairs-count", "repairs-total-value", "repairs-average-value", "cancellations-count", "clients-count", "new-clients-count", "returning-clients-count", "comparative-period"])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["error", "onReload"])
    ]),
    _: 1
  }, 8, ["class"]))
}