
import { defineComponent, PropType } from 'vue';
import {
  DmTile,
  DmLoadingBlend
} from '@dobrymechanik/vue-ui';
import { StatsRepairSource } from '@/api/models';
import VueApexCharts from 'vue3-apexcharts';
import { ApexOptions } from 'apexcharts';

export default defineComponent({
  name: 'StatsRepairsSources',
  components: {
    DmTile,
    DmLoadingBlend,
    VueApexCharts
  },
  props: {
    items: {
      type: Array as PropType<StatsRepairSource[]>,
      required: true
    },
    loading: {
      type: Boolean as PropType<boolean>,
      required: true
    }
  },
  data () {
    return {
      seriesColors: [
        {
          name: 'DobryMechanik.pl',
          color: '#176ae6'
        },
        {
          name: 'Zilo',
          color: '#172897'
        }
      ]
    };
  },
  computed: {
    chartOptions (): ApexOptions {
      const colors: string[] = this.items.map(i => this.seriesColors.find(s => s.name === i.name)?.color || '');
      return {
        chart: {
          type: 'donut'
        },
        colors,
        labels: this.items.map(i => i.name),
        dataLabels: {
          background: {
            enabled: true,
            opacity: 0.7,
            foreColor: '#232323',
            dropShadow: {}
          },
          dropShadow: {
            enabled: false
          }
        },
        legend: {
          position: 'bottom'
        },
        plotOptions: {
          pie: {
            donut: {
              size: '50%'
            },
            expandOnClick: false
          }
        }
      };
    },
    series (): any[] {
      return this.items.map(i => i.repairsNumber);
    }
  }
});
