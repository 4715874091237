import { toDisplayString as _toDisplayString, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VueApexCharts = _resolveComponent("VueApexCharts")
  const _component_DmLoadingBlend = _resolveComponent("DmLoadingBlend")
  const _component_DmTile = _resolveComponent("DmTile")

  return (_openBlock(), _createBlock(_component_DmTile, {
    color: "white",
    rounded: "",
    bordered: "",
    class: _ctx.$bem({})
  }, {
    default: _withCtx(() => [
      _createVNode("h3", {
        class: _ctx.$bem({e: 'title'})
      }, _toDisplayString(_ctx.$t('statistics.repairsSources.title')), 3),
      _createVNode(_component_VueApexCharts, {
        height: "200",
        type: "donut",
        options: _ctx.chartOptions,
        series: _ctx.series,
        class: _ctx.$bem({e: 'chart'})
      }, null, 8, ["options", "series", "class"]),
      _createVNode(_component_DmLoadingBlend, { loading: _ctx.loading }, null, 8, ["loading"])
    ]),
    _: 1
  }, 8, ["class"]))
}