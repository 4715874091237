import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DmButton = _resolveComponent("DmButton")
  const _component_DmTile = _resolveComponent("DmTile")
  const _component_DmSelect = _resolveComponent("DmSelect")

  return (_openBlock(), _createBlock("div", {
    class: _ctx.$bem({})
  }, [
    _createVNode(_component_DmTile, {
      bordered: "",
      rounded: "",
      class: _ctx.$bem({e: 'period-types'})
    }, {
      default: _withCtx(() => [
        _createVNode(_component_DmButton, {
          class: _ctx.periodTypeButtonClasses(_ctx.PeriodType.WEEK),
          "data-test": "stats-period-type-week",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.setPeriodType(_ctx.PeriodType.WEEK)))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('statistics.controls.weeks')), 1)
          ]),
          _: 1
        }, 8, ["class"]),
        _createVNode(_component_DmButton, {
          class: _ctx.periodTypeButtonClasses(_ctx.PeriodType.MONTH),
          "data-test": "stats-period-type-month",
          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.setPeriodType(_ctx.PeriodType.MONTH)))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('statistics.controls.months')), 1)
          ]),
          _: 1
        }, 8, ["class"]),
        _createVNode(_component_DmButton, {
          class: _ctx.periodTypeButtonClasses(_ctx.PeriodType.QUARTER),
          "data-test": "stats-period-type-quarter",
          onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.setPeriodType(_ctx.PeriodType.QUARTER)))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('statistics.controls.quarters')), 1)
          ]),
          _: 1
        }, 8, ["class"]),
        _createVNode(_component_DmButton, {
          class: _ctx.periodTypeButtonClasses(_ctx.PeriodType.YEAR),
          "data-test": "stats-period-type-year",
          onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.setPeriodType(_ctx.PeriodType.YEAR)))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('statistics.controls.years')), 1)
          ]),
          _: 1
        }, 8, ["class"])
      ]),
      _: 1
    }, 8, ["class"]),
    _createVNode(_component_DmSelect, {
      "model-value": _ctx.workspace.key,
      items: _ctx.workspaces,
      rounded: "",
      bordered: "",
      class: _ctx.$bem({e: 'select-workspace'}),
      "data-test": "stats-workspace",
      onChange: _ctx.setWorkspace
    }, null, 8, ["model-value", "items", "class", "onChange"]),
    _createVNode("div", {
      class: _ctx.$bem({e: 'periods'})
    }, [
      _createVNode("div", {
        class: _ctx.$bem({e: 'period'})
      }, [
        _createVNode("div", {
          class: _ctx.$bem({e: 'period-label'})
        }, [
          _createVNode(_component_DmTile, {
            color: "primary",
            class: _ctx.$bem({e: 'period-circle'})
          }, null, 8, ["class"])
        ], 2),
        _createVNode(_component_DmSelect, {
          "model-value": _ctx.analyzedPeriod.key,
          items: _ctx.periods,
          rounded: "",
          bordered: "",
          class: _ctx.$bem({e: 'select-period'}),
          "data-test": "stats-analyzed-period",
          onChange: _ctx.setAnalyzedPeriod
        }, null, 8, ["model-value", "items", "class", "onChange"])
      ], 2),
      _createVNode("div", {
        class: _ctx.$bem({e: 'period'})
      }, [
        _createVNode("div", {
          class: _ctx.$bem({e: 'period-label'})
        }, [
          _createVNode("div", {
            class: _ctx.$bem({e: 'period-description'})
          }, _toDisplayString(_ctx.$t('statistics.controls.compareWith')), 3),
          _createVNode(_component_DmTile, {
            color: "secondary",
            dark: "",
            class: _ctx.$bem({e: 'period-circle'})
          }, null, 8, ["class"])
        ], 2),
        _createVNode(_component_DmSelect, {
          "model-value": _ctx.comparativePeriod.key,
          items: _ctx.periods,
          rounded: "",
          bordered: "",
          class: _ctx.$bem({e: 'select-period'}),
          "data-test": "stats-comparative-period",
          onChange: _ctx.setComparativePeriod
        }, null, 8, ["model-value", "items", "class", "onChange"])
      ], 2)
    ], 2)
  ], 2))
}