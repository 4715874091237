
import { defineComponent } from 'vue';
import {
  StatsControls,
  StatsChart,
  StatsBoxes,
  StatsTopRepairs,
  StatsRepairsSources
} from './_components';
import { AppWrapper } from '@/components';
import {
  DmAsyncContent,
  DmGrid,
  DmGridItem
} from '@dobrymechanik/vue-ui';
import { PeriodType } from '@/models/stats';
import {
  StatsChartPoint,
  StatsBox,
  StatsRepair,
  Stats,
  StatsRepairSource,
  CalendarResource
} from '@/api/models';
import { URI_STATISTICS, URI_CALENDAR_RESOURCES, URI_STATISTICS_PER_WORKSPACE } from '@/api/endpoints';

const defaultStatsBox: StatsBox = {
  primary: 0,
  compared: 0
};

const defaultWorkspace: {key: string; text: string} = {
  key: 'all',
  text: 'Wszystkie stanowiska'
};

export default defineComponent({
  name: 'Stats',
  components: {
    AppWrapper,
    StatsControls,
    StatsChart,
    StatsBoxes,
    StatsTopRepairs,
    StatsRepairsSources,
    DmAsyncContent,
    DmGrid,
    DmGridItem
  },
  data () {
    return {
      loading: true as boolean,
      error: false as boolean,
      periodType: PeriodType.MONTH,
      analyzedPeriod: null as {key: string; text: string} | null,
      comparativePeriod: null as {key: string; text: string} | null,
      chartData: [] as StatsChartPoint[],
      repairsCount: defaultStatsBox as StatsBox,
      repairsTotalValue: defaultStatsBox as StatsBox,
      repairsAverageValue: defaultStatsBox as StatsBox,
      cancellationsCount: defaultStatsBox as StatsBox,
      clientsCount: defaultStatsBox as StatsBox,
      newClientsCount: defaultStatsBox as StatsBox,
      returningClientsCount: defaultStatsBox as StatsBox,
      isComparativePeriodChanged: false as boolean,
      topRepairs: [] as StatsRepair[],
      repairSources: [] as StatsRepairSource[],
      workspaces: [defaultWorkspace] as {key: string; text: string}[],
      workspace: defaultWorkspace as {key: string; text: string}
    };
  },
  computed: {
    periods (): any[] {
      const addDays = (d: Date, days: number) => {
        return new Date(d.getTime() + (days * 24 * 60 * 60 * 1000));
      };
      const periods: any[] = [];

      const startYear = 2020;
      const startMonth = 12;

      const endDate = addDays(new Date(), -1);
      const endYear = endDate.getFullYear();
      const endMonth = endDate.getMonth() + 1;

      const formatDate = (year: number, month: number, day: number): string => {
        return `${year}-${(`00${month}`).substr(-2)}-${(`00${day}`).substr(-2)}`;
      };

      if (this.periodType === PeriodType.WEEK) {
        const startDate = new Date(startYear, startMonth - 1, 1);
        const startDateWeekday = startDate.getDay();
        const firstMonday = addDays(startDate, -startDateWeekday + 1);
        let tmpDate = firstMonday;
        while (tmpDate <= endDate) {
          const day = tmpDate.getDate();
          const month = tmpDate.getMonth() + 1;
          const year = tmpDate.getFullYear();
          periods.push({
            key: formatDate(year, month, day),
            text: this.$t('statistics.controls.weekFrom', { week: tmpDate.toLocaleDateString(this.$i18n.locale, { day: '2-digit', month: '2-digit', year: 'numeric' }) })
          });
          tmpDate = addDays(tmpDate, 7);
        }
      } else if (this.periodType === PeriodType.MONTH) {
        let year = startYear;
        let month = startMonth;
        while (year < endYear || (year === endYear && month <= endMonth)) {
          periods.push({
            key: formatDate(year, month, 1),
            text: `${(`00${month}`).substr(-2)}.${year}`
          });
          if (month === 12) {
            month = 1;
            year++;
          } else {
            month++;
          }
        }
      } else if (this.periodType === PeriodType.QUARTER) {
        let year = startYear;
        let quarter = Math.ceil(startMonth / 3);
        const endQuarter = Math.ceil(endMonth / 3);
        while (year < endYear || (year === endYear && quarter <= endQuarter)) {
          periods.push({
            key: formatDate(year, (quarter * 3) - 2, 1),
            text: `Q${quarter} ${year}`
          });
          if (quarter === 4) {
            quarter = 1;
            year++;
          } else {
            quarter++;
          }
        }
      } else if (this.periodType === PeriodType.YEAR) {
        let year = startYear;
        while (year <= endYear) {
          periods.push({
            key: formatDate(year, 1, 1),
            text: year.toString()
          });
          year++;
        }
      }
      return periods.reverse();
    }
  },
  created () {
    this.fetchResources();
    this.setPeriodType(PeriodType.MONTH);
  },
  methods: {
    async fetchStats (): Promise<void> {
      this.loading = true;
      this.error = false;
      if (this.analyzedPeriod && this.comparativePeriod) {
        try {
          let response;
          if (this.workspace.key === defaultWorkspace.key) {
            response = (await this.$http.get<Stats>(URI_STATISTICS(this.periodType, this.analyzedPeriod.key, this.comparativePeriod.key))).data;
          } else {
            response = (await this.$http.get<Stats>(URI_STATISTICS_PER_WORKSPACE(this.periodType, this.analyzedPeriod.key, this.comparativePeriod.key, this.workspace.key))).data;
          }
          this.chartData = response.chartData;
          this.repairsCount = response.repairsCount;
          this.repairsTotalValue = response.repairsTotalValue;
          this.repairsAverageValue = response.repairsAverageValue;
          this.cancellationsCount = response.cancellationsCount;
          this.clientsCount = response.clientsCount;
          this.newClientsCount = response.newClientsCount;
          this.returningClientsCount = response.returningClientsCount;
          this.topRepairs = response.topRepairs;
          this.repairSources = response.repairsSources;
        } catch (e) {
          this.error = true;
        }
        this.loading = false;
      } else {
        this.loading = false;
      }
    },
    setPeriodType (periodType: PeriodType): void {
      this.periodType = periodType;
      this.analyzedPeriod = this.periods[0];
      this.comparativePeriod = this.periods[1];
      this.fetchStats();
    },
    setAnalyzedPeriod (period: {key: string; text: string}): void {
      this.analyzedPeriod = period;
      this.fetchStats();
    },
    setWorkspace (workspace: {key: string; text: string}): void {
      this.workspace = workspace;
      this.fetchStats();
    },
    setComparativePeriod (period: {key: string; text: string}): void {
      this.comparativePeriod = period;
      this.fetchStats();
    },
    async fetchResources (): Promise<void> {
      const res = (await this.$http.get<CalendarResource[]>(URI_CALENDAR_RESOURCES)).data;
      this.workspaces = [...this.workspaces, ...this.mapWorkspaces(res)];
    },
    mapWorkspaces (resources: CalendarResource[]): {key: string; text: string}[] {
      return resources.map(resource => {
        return {
          key: resource.id,
          text: resource.title
        };
      });
    }
  }
});
