import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_StatsBoxesItem = _resolveComponent("StatsBoxesItem")
  const _component_DmSimpleGridItem = _resolveComponent("DmSimpleGridItem")
  const _component_DmSimpleGrid = _resolveComponent("DmSimpleGrid")

  return (_openBlock(), _createBlock("div", {
    class: _ctx.$bem({})
  }, [
    _createVNode(_component_DmSimpleGrid, {
      "elements-lg": 3,
      "spacings-size": "md"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_DmSimpleGridItem, null, {
          default: _withCtx(() => [
            _createVNode(_component_StatsBoxesItem, {
              title: _ctx.$t('statistics.boxes.allRepairs'),
              icon: "wrench",
              loading: _ctx.loading
            }, {
              value: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.repairsCount.primary), 1)
              ]),
              description: _withCtx(() => [
                _createVNode("strong", {
                  class: _ctx.comparisonClass(_ctx.repairsCount.primary, _ctx.repairsCount.compared)
                }, _toDisplayString(_ctx.getPercentage(_ctx.repairsCount.primary / _ctx.repairsCount.compared - 1)), 3),
                _createTextVNode(" vs " + _toDisplayString(_ctx.comparativePeriod), 1)
              ]),
              _: 1
            }, 8, ["title", "loading"])
          ]),
          _: 1
        }),
        _createVNode(_component_DmSimpleGridItem, null, {
          default: _withCtx(() => [
            _createVNode(_component_StatsBoxesItem, {
              title: _ctx.$t('statistics.boxes.repairsValue'),
              icon: "dollar-sign",
              loading: _ctx.loading
            }, {
              value: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.repairsTotalValue.primary) + " zł ", 1)
              ]),
              description: _withCtx(() => [
                _createVNode("strong", {
                  class: _ctx.comparisonClass(_ctx.repairsTotalValue.primary, _ctx.repairsTotalValue.compared)
                }, _toDisplayString(_ctx.getPercentage(_ctx.repairsTotalValue.primary / _ctx.repairsTotalValue.compared - 1)), 3),
                _createTextVNode(" vs " + _toDisplayString(_ctx.comparativePeriod), 1)
              ]),
              _: 1
            }, 8, ["title", "loading"])
          ]),
          _: 1
        }),
        _createVNode(_component_DmSimpleGridItem, null, {
          default: _withCtx(() => [
            _createVNode(_component_StatsBoxesItem, {
              title: _ctx.$t('statistics.boxes.averageRepairValue'),
              icon: "hand-holding-usd",
              loading: _ctx.loading
            }, {
              value: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.repairsAverageValue.primary) + " zł ", 1)
              ]),
              description: _withCtx(() => [
                _createVNode("strong", {
                  class: _ctx.comparisonClass(_ctx.repairsAverageValue.primary, _ctx.repairsAverageValue.compared)
                }, _toDisplayString(_ctx.getPercentage(_ctx.repairsAverageValue.primary / _ctx.repairsAverageValue.compared - 1)), 3),
                _createTextVNode(" vs " + _toDisplayString(_ctx.comparativePeriod), 1)
              ]),
              _: 1
            }, 8, ["title", "loading"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_DmSimpleGrid, {
      "elements-sm": 2,
      "elements-xl": 4,
      "spacings-size": "md"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_DmSimpleGridItem, null, {
          default: _withCtx(() => [
            _createVNode(_component_StatsBoxesItem, {
              title: _ctx.$t('statistics.boxes.numberOfCancellations'),
              loading: _ctx.loading
            }, {
              value: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.cancellationsCount.primary), 1)
              ]),
              description: _withCtx(() => [
                _createVNode("strong", {
                  class: _ctx.comparisonClass(_ctx.cancellationsCount.compared, _ctx.cancellationsCount.primary)
                }, _toDisplayString(_ctx.getPercentage(_ctx.cancellationsCount.primary / _ctx.cancellationsCount.compared - 1)), 3),
                _createTextVNode(" vs " + _toDisplayString(_ctx.comparativePeriod), 1)
              ]),
              _: 1
            }, 8, ["title", "loading"])
          ]),
          _: 1
        }),
        _createVNode(_component_DmSimpleGridItem, null, {
          default: _withCtx(() => [
            _createVNode(_component_StatsBoxesItem, {
              title: _ctx.$t('statistics.boxes.numberOfClients'),
              loading: _ctx.loading
            }, {
              value: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.clientsCount.primary), 1)
              ]),
              description: _withCtx(() => [
                _createVNode("strong", {
                  class: _ctx.comparisonClass(_ctx.clientsCount.primary, _ctx.clientsCount.compared)
                }, _toDisplayString(_ctx.getDiff(_ctx.clientsCount.primary - _ctx.clientsCount.compared)), 3),
                _createTextVNode(" vs " + _toDisplayString(_ctx.comparativePeriod), 1)
              ]),
              _: 1
            }, 8, ["title", "loading"])
          ]),
          _: 1
        }),
        _createVNode(_component_DmSimpleGridItem, null, {
          default: _withCtx(() => [
            _createVNode(_component_StatsBoxesItem, {
              title: _ctx.$t('statistics.boxes.numberOfNewClients'),
              loading: _ctx.loading
            }, {
              value: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.newClientsCount.primary), 1)
              ]),
              description: _withCtx(() => [
                _createVNode("strong", {
                  class: _ctx.comparisonClass(_ctx.newClientsCount.primary, _ctx.newClientsCount.compared)
                }, _toDisplayString(_ctx.getDiff(_ctx.newClientsCount.primary - _ctx.newClientsCount.compared)), 3),
                _createTextVNode(" vs " + _toDisplayString(_ctx.comparativePeriod), 1)
              ]),
              _: 1
            }, 8, ["title", "loading"])
          ]),
          _: 1
        }),
        _createVNode(_component_DmSimpleGridItem, null, {
          default: _withCtx(() => [
            _createVNode(_component_StatsBoxesItem, {
              title: _ctx.$t('statistics.boxes.numberOfReturningClients'),
              loading: _ctx.loading
            }, {
              value: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.returningClientsCount.primary), 1)
              ]),
              description: _withCtx(() => [
                _createVNode("strong", {
                  class: _ctx.comparisonClass(_ctx.returningClientsCount.primary, _ctx.returningClientsCount.compared)
                }, _toDisplayString(_ctx.getDiff(_ctx.returningClientsCount.primary - _ctx.returningClientsCount.compared)), 3),
                _createTextVNode(" vs " + _toDisplayString(_ctx.comparativePeriod), 1)
              ]),
              _: 1
            }, 8, ["title", "loading"])
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ], 2))
}