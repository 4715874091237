
import { defineComponent, PropType } from 'vue';
import {
  DmTile,
  DmIcon,
  DmSpinner
} from '@dobrymechanik/vue-ui';

export default defineComponent({
  name: 'StatsBoxesItem',
  components: {
    DmTile,
    DmIcon,
    DmSpinner
  },
  props: {
    title: {
      type: String as PropType<string | null>,
      default: null
    },
    icon: {
      type: String as PropType<string | null>,
      default: null
    },
    loading: {
      type: Boolean as PropType<boolean>,
      required: true
    }
  }
});
