import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VueApexCharts = _resolveComponent("VueApexCharts")
  const _component_DmLoadingBlend = _resolveComponent("DmLoadingBlend")
  const _component_DmTile = _resolveComponent("DmTile")

  return (_openBlock(), _createBlock(_component_DmTile, {
    color: "white",
    rounded: "",
    bordered: "",
    class: _ctx.$bem({})
  }, {
    default: _withCtx(() => [
      _createVNode(_component_VueApexCharts, {
        height: "300",
        type: "line",
        options: _ctx.chartOptions,
        series: _ctx.series
      }, null, 8, ["options", "series"]),
      _createVNode(_component_DmLoadingBlend, { loading: _ctx.loading }, null, 8, ["loading"])
    ]),
    _: 1
  }, 8, ["class"]))
}