
import { defineComponent, PropType, inject } from 'vue';
import {
  DmTile,
  DmButton,
  DmSelect
} from '@dobrymechanik/vue-ui';
import { PeriodType } from '@/models/stats';
import { CssClass } from '@/helpers/css-classes';
import { TrackEvent, TrackedEventType, TrackedEventCategory } from '@/models/track-event';

export default defineComponent({
  name: 'StatsControls',
  components: {
    DmTile,
    DmButton,
    DmSelect
  },
  props: {
    periodType: {
      type: String as PropType<PeriodType>,
      required: true
    },
    analyzedPeriod: {
      type: Object as PropType<{key: string; text: string} | null>,
      default: null
    },
    comparativePeriod: {
      type: Object as PropType<{key: string; text: string} | null>,
      default: null
    },
    periods: {
      type: Array as PropType<{key: string; text: string}[]>,
      required: true
    },
    workspaces: {
      type: Array as PropType<{key: string; text: string}[] | null>,
      required: true
    },
    workspace: {
      type: Object as PropType<{key: string; text: string} | null>,
      default: null
    }
  },
  emits: [
    'set-period-type',
    'set-analyzed-period',
    'set-comparative-period',
    'set-workspace'
  ],
  setup () {
    return {
      trackEvent: inject('trackEvent') as TrackEvent
    };
  },
  data () {
    return {
      PeriodType: PeriodType
    };
  },
  methods: {
    periodTypeButtonClasses (periodType: PeriodType): CssClass[] {
      return [
        ...this.$bem(
          {
            e: 'period-type-button',
            m: {
              active: periodType === this.periodType
            }
          }
        ),
        'is-hoverable'
      ];
    },
    setPeriodType (periodType: PeriodType): void {
      if (periodType !== this.periodType) {
        this.$emit('set-period-type', periodType);
        this.trackEvent({
          event: TrackedEventType.CLICK,
          category: TrackedEventCategory.STATS,
          action: 'Time scope change',
          label: periodType
        });
      }
    },
    setAnalyzedPeriod (e: {key: string; text: string}): void {
      this.$emit('set-analyzed-period', e);
    },
    setComparativePeriod (e: {key: string; text: string}): void {
      this.$emit('set-comparative-period', e);
    },
    setWorkspace (e: {key: string; text: string}): void {
      this.$emit('set-workspace', e);
    }
  }
});
